import {HomePage, HomePageProps} from "@blogs/pages";
import {SiteIndexHelpers} from "@blogs/deploy";
import Head from "next/head";

export const getStaticProps = SiteIndexHelpers.buildGetStaticProps({types: ['posts']})


const Page = (props: HomePageProps) => (
  <>
    <Head>
      <meta name='ir-site-verification-token'

        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
            value='-380292245'/>
    </Head>

    <HomePage
      {...props}
      metaDescription={`Welcome to RoamingSurfer.com, your ultimate resource for surfing adventures worldwide. Join Shane Daugherty as he uncovers secret surf spots, shares travel tips, and chronicles his journey surfing the world's best waves. Dive in and start your own surfing journey!`}
      metaTitle={`RoamingSurfer.com | Surfing Adventures, Tips & Travel Guides by Shane Daugherty`}
    />
  </>
)

export default Page
